import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Button from "../../../../components/buttons/Button";
import MediaQuery from "../../../layout/MediaQuery";
import Tagline from "../../../texts/Tagline";
import Content from "../../../layout/Content/Content";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import RichDescription from "../../../texts/RichDescription";

const Container = Styled.div`
  margin: 0 0 16rem 0;
  
  ${Media.lessThan("medium")`
    margin: 0 0 9rem 0;
  `}
  
  ${Media.lessThan("small")`
    margin: 0 0 7rem 0;
  `}
`;

const Box = Styled.div`
  background: ${(props) => props.theme.colors.gray.lighter};
  padding: 3rem 0 0 0;
  border-radius: 2rem;
  
  ${Media.greaterThan("medium")`
    padding: 6rem 0 0 0;
  `}
`;

const Text = Styled.div`
  width: 64rem;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
  
  ${Media.greaterThan("large")`
    width: 85rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  text-align: center;
  color: #7f7f7f;
`;

const HeadlineExtended = Styled(Headline)`
  text-align: center;
`;

const RichDescriptionExtended = Styled(RichDescription)`
  text-align: center;
  margin: 0 0 5rem 0;
  
    ${Media.lessThan("small")`
    margin: 0 0 3rem 0;
  `}
  
  ${Media.greaterThan("medium")`
    margin: 0 0 5rem 0;
  `}
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
`;

const NavButtonLink = Styled.a`
  text-align: center;
  display: block;
`;

const ImageMobile = Styled(Image)`
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
`;

const VideoContainer = Styled.div`
  position: relative;
  height: 0;
  max-width: 900px;
  padding-bottom: 25%;
  margin: 0 auto;
  overflow: hidden;
  
  ${Media.greaterThan("medium")`
    margin: auto;
  `}
`;

const ImgContainer = Styled.div`

`;

const VideoContent = Styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
`;

type SectionWithVerticalVideoProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  videoName: string;
  link?: string;
  tagAsTitle?: boolean;
};

const SectionWithVerticalVideo = ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  videoName,
  link,
  tagAsTitle,
}: SectionWithVerticalVideoProps) => (
  <Container>
    <Content>
      <Box>
        <Text>
          {tagline && tagAsTitle && (
            <TaglineExtended as="h1">{tagline}</TaglineExtended>
          )}
          {tagAsTitle && (
            <HeadlineExtended as="h2">{headline}</HeadlineExtended>
          )}

          {tagline && !tagAsTitle && (
            <TaglineExtended>{tagline}</TaglineExtended>
          )}
          {!tagAsTitle && <HeadlineExtended>{headline}</HeadlineExtended>}

          <RichDescriptionExtended content={description} />

          {link && (
            <NavButtonLink href={link}>
              <ExtendedButton variant="primarylight" solid>
                Read more
              </ExtendedButton>
            </NavButtonLink>
          )}
        </Text>

        <VideoContainer>
          <VideoContent>
            <Video autoPlay muted loop playsInline>
              <source src={"/videos/" + videoName + ".mp4"} type="video/mp4" />
              <source src={"/videos/" + videoName + ".ogv"} type="video/ogv" />
              <source
                src={"/videos/" + videoName + ".webm"}
                type="video/webm"
              />
              <img
                src={"/videos/" + videoName + ".png"}
                alt={"Video of " + imageAlt}
              />
            </Video>
          </VideoContent>

          <ImgContainer>
            <MediaQuery lessThan="medium">
              <ImageMobile
                fluid={"fluid" in imageB ? imageB.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>

            <MediaQuery greaterThan="medium">
              <ImageDesktop
                fluid={"fluid" in imageA ? imageA.fluid : ""}
                alt={imageAlt}
              />
            </MediaQuery>
          </ImgContainer>
        </VideoContainer>
      </Box>
    </Content>
  </Container>
);

export default SectionWithVerticalVideo;

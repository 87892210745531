import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";

import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import HomeConversion from "../../components/pages/home/HomeConversion";
import SectionWithVerticalVideo from "../../components/pages/solutions/SectionWithVerticalVideo";
import SectionWithRightVideoWireframe from "../../components/pages/solutions/SectionWithRightVideoWireframe";
import HeroBigTextBannerSquareImage from "../../components/pages/solutions/HeroBigTextBannerSquareImage";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import Shoutout from "../../components/pages/signup/Shoutout";
import FAQSection from "../../components/pages/faq/FAQSection";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import Content from "../../components/layout/Content/Content";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/What-are-wireframes-A-beginners-guide.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/How-to-Create-Wireframes-in-5-Steps-with-Uizard.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/How-to-go-from-Wireframe-to-Low-fidelity-Prototype.png";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Wireframing = ({ data }) => (
  <Layout>
    <Meta
      title="Online Wireframing Tool | Easy UI Wireframe Design | Uizard"
      description="Drag-and-drop components and templates to design mobile apps, web apps, websites, and desktop software. It's quick and easy! Try it today."
      url="/wireframing/"
      faqschema='{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What are online wireframing tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Online wireframing tools are tools designed to help users build a UX/UI wireframe or a set of wireframes. Typically, wireframing software focuses on speed and accessibility, supporting the user to create wireframes as quickly and as easily as possible. Wireframing tools, such as Uizard, include predefined wireframing components that the user can drag and drop onto their designs. "
            }
          },
          {
            "@type": "Question",
            "name": "What do online wireframing tools do?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Online wireframe tools allow users to rapidly create wireframes for their projects or products. Designed with ease of use in mind, they often come with smart collaboration features to support team working. Easy UX/UI wireframing tools such as Uizard also support the user right through to the prototyping stage, giving full-spectrum design coverage."
            }
          },
          {
            "@type": "Question",
            "name": "How do I use online wireframing tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Most online wireframing design tools are built with usability in mind, with tools such as Uizard being specifically made for non-designers among other use cases. This means UX/UI wireframing software is often very user friendly. Once a user signs up they can begin working on their own wireframes and have them ready in minutes."
            }
          },
          {
            "@type": "Question",
            "name": "Which online wireframing tool is best?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Which online wireframe software is best largely comes down to the user and what they are looking for from the tool they are using. For non-designers, or those with minimal design experience, easy UX/UI wireframing tools like Uizard will likely suit better due to their ease of use and smart collaboration features, whereas tools like Figma are more aligned with designers who have advanced skills."
            }
          },
          {
            "@type": "Question",
            "name": "Are there any free online wireframing tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Some mainstream online wireframing tools do not offer free versions and instead utilize pricing tiers depending on the scope of use. There are some prominent tools that do offer free versions of their software however, with both Figma and Uizard offering free plans for UX/UI wireframing."
            }
          }
        ]
      }'
    />

    <HeroBigTextBannerSquareImage
      tagline="Online Wireframing Tool"
      headline="Quick and easy wireframing"
      description="Design UI wireframes for apps and websites rapidly and collaboratively. UI wireframing has never been so easy."
      imageA={data.prototypingImageA.childImageSharp}
      imageB={data.prototypingImageB.childImageSharp}
      imageAlt="users collaboratively designing a mobile app wireframe in uizard"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle={true}
    />

    <SectionWithVerticalVideo
      headline="Collaborative wireframing"
      description="Collaborative UI wireframing with your team in Uizard is fast and easy. Simply share a link to your project and start designing wireframes collaboratively straight from your web browser. No software install required, just pure productivity. <a:https://uizard.io/ux-design/>UX design</a> has never been so easy."
      imageA={data.collaborativeWireframingBig.childImageSharp}
      imageB={data.collaborativeWireframingSmall.childImageSharp}
      imageAlt="designing multiple wireframing screeens in Uizard"
      videoName="uizard-collaborative-wireframing"
    />

    <SectionWithRightVideoWireframe
      headline="Transform wireframes into UI mockups"
      description="Disable wireframe mode at any time to render your project as a high-fidelity <a:https://uizard.io/mockups/>UI mockup</a>. Test different styles instantly with the power of AI to experiment with your product, app, or web design."
      imageA={data.wireframesToMockupsBig.childImageSharp}
      imageB={data.wireframesToMockupsSmall.childImageSharp}
      videoName="uizard-wireframe-high-fi-mode"
      imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
      link="/mockups/"
    />

    <HomeConversion
      tagline="AI-Powered Wireframing"
      headline="Drawing to wireframe, with the power of AI"
      description="If you and your team sketch your ideas with pen and paper, Uizard's Design Assistant can automatically transform your hand-drawn wireframe into customizable UI designs. No more lost sketches!"
      imageA={data.conversionImageA.childImageSharp}
      imageB={data.conversionImageB.childImageSharp}
      imageAlt="Transform rough wireframe sketches to digital screens with Uizard."
      link="/design-assistant/"
    />

    <HomeFeatured />

    <BlogHighlight
      title="Learn UX/UI wireframing"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/what-is-a-wireframe-and-why-do-i-need-one/",
          alt: "Thumbnail for blog titled What are wireframes? A beginner's guide",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/how-to-create-wireframes-in-5-steps-with-uizard/",
          alt: "Thumbnail for blog titled How to Create Wireframes in 5 Steps with Uizard",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/how-to-go-from-wireframe-to-low-fidelity-prototype/",
          alt: "Thumbnail for blog titled How to go from Wireframe to Low-fidelity Prototype",
        },
      ]}
    />

    <FAQSection
      title={"Wireframing FAQs"}
      questions={[
        {
          question: "What are online wireframing tools?",
          answer:
            "Online wireframing tools are tools designed to help users build a UX/UI wireframe or a set of wireframes. Typically, wireframing software focuses on speed and accessibility, supporting the user to create wireframes as quickly and as easily as possible. Wireframing tools, such as Uizard, include predefined wireframing components that the user can drag and drop onto their designs. ",
        },
        {
          question: "What do online wireframing tools do?",
          answer:
            "Online wireframe tools allow users to rapidly create UX/UI wireframes for their projects or products. Designed with ease of use in mind, they often come with smart collaboration features to support team working. Easy wireframing tools such as Uizard also support the user right through to the prototyping stage, giving full-spectrum design coverage.",
        },
        {
          question: "How do I use online wireframe tools?",
          answer:
            "Most online wireframing design tools are built with usability in mind, with tools such as Uizard being specifically made for non-designers among other use cases. This means UX/UI wireframing software is often very user friendly. Once a user signs up they can begin working on their own wireframes and have them ready in minutes.",
        },
        {
          question: "Which online wireframe tool is best?",
          answer:
            "Which online wireframe software is best largely comes down to the user and what they are looking for from the tool they are using. For non-designers, or those with minimal design experience, easy UX/UI wireframing tools like Uizard will likely suit better due to their ease of use and smart collaboration features, whereas tools like Figma are more aligned with designers who have advanced skills.",
        },
        {
          question: "Are there any free online wireframing tools?",
          answer:
            "Some mainstream online wireframing tools do not offer free versions and instead utilize pricing tiers depending on the scope of use. There are some prominent tools that do offer free versions of their software however, with both Figma and Uizard offering free plans for UX/UI wireframing.",
        },
      ]}
    />

    <ContentExtended>
      <Shoutout
        headline="UI wireframing made easy"
        description="Empower your entire team today"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Wireframing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "wireframing/Wireframe_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "wireframing/Wireframe_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 548, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborativeWireframingBig: file(
      relativePath: { eq: "wireframing/Collaborative_Wireframing_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborativeWireframingSmall: file(
      relativePath: { eq: "wireframing/Collaborative_Wireframing_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsBig: file(
      relativePath: { eq: "wireframing/WireframesToMockups_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsSmall: file(
      relativePath: { eq: "wireframing/WireframesToMockups_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "wireframing/DrawingToWireframe_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 860, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "wireframing/DrawingToWireframe_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

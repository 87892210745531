import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Button from "../../../../components/buttons/Button";

import Content from "../../../layout/Content/Content";
import Tagline from "../../../texts/Tagline";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  margin: 0 0 8rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("760px")`
    margin: 0 0 10rem 0;
  `}

  ${Media.greaterThan("large")`
    margin: 0 0 15rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  position: relative;
  padding: 0 0 12rem 0;
  
  ${Media.lessThan("980px")`
    max-width: calc(100% - 5rem);
    margin: 0 0 0 auto;
  `}
  
  ${Media.lessThan("760px")`
    max-width: 100%;
    margin: 0;
    padding: 0 5rem 5rem;
    height: 98rem;
  `}

  ${Media.greaterThan("760px")`
    padding: 12rem 0;
  `}
`;

const Box = Styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100%);
  padding-bottom: 96rem;
  height: 0;
  background-color: ${(props) => props.theme.colors.primary};

  ${Media.greaterThan("760px")`
    width: 75%;
    padding-bottom: 60rem;
  `}

  ${Media.greaterThan("large")`
    padding-bottom: 58.5%;
  `}
`;

const BoxA = Styled(Box)`
  background-color: ${(props) => props.theme.colors.primary};
  right: 0;
  border-radius: 2rem;
  height: 96rem;

  ${Media.lessThan("760px")`
    top: 2rem;
  `}

  ${Media.greaterThan("760px")`
    right: 0;
    bottom: 0;
    height: 60rem;
  `}
  
  ${Media.greaterThan("large")`
      height: 75rem;
  `}
`;

const BoxB = Styled(Box)`
  background-color: ${(props) => props.theme.colors.gray.lighter};
  right: 2rem;
  border-radius: 2rem;
  height: 96rem;

  ${Media.lessThan("760px")`
    top: 0rem;
  `}

  ${Media.greaterThan("760px")`
    right: 3rem;
    bottom: 3rem;
    height: 60rem;
  `}
  
  ${Media.greaterThan("large")`
      height: 72rem;
  `}
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-right: 2rem;
  
  ${Media.greaterThan("760px")`
  padding-right: 6rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
  
  ${Media.greaterThan("large")`
    padding-right: 10rem;
  `}

`;

const Column = Styled.div`
  ${Media.greaterThan("large")`
    &:first-of-type {
      padding-left: 9rem;
    }
  `}
`;

const Text = Styled.div`
  position: relative;
  margin-bottom: 6rem;
  width: 32rem;
  
  ${Media.greaterThan("1080px")`
    width: 43rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  margin-top: 7rem;
  color: #7f7f7f;

  ${Media.greaterThan("760px")`
    margin-top: 0;
  `}
`;

const DescriptionExtended = Styled(Description)`
  margin-bottom: 0;
`;

const ImageA = Styled(Image)`
  width: 32.3rem;
  max-width: calc(100% - 0.75rem);
  margin: 5rem auto 0 auto;
  
  ${Media.greaterThan("700px")`
    margin: 0 auto
  `}
  
  ${Media.greaterThan("1280px")`
    width: 45rem;
  `}

  ${Media.greaterThan("760px")`
    max-width: 100%;
  `}
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan("medium")`
    margin-bottom: 0;
  `}

  ${Media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

type HomeConversionProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  link?: string;
  cta?: string;
  tagAsTitle?: boolean;
};

const HomeConversion = ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  link,
  cta,
  tagAsTitle,
}: HomeConversionProps) => (
  <Container>
    <ContentExtended>
      <BoxA />
      <BoxB />

      <Columns>
        <Column>
          <MediaQuery lessThan="medium">
            <ImageA
              fluid={"fluid" in imageB ? imageB.fluid : ""}
              alt={imageAlt}
            />
          </MediaQuery>
          <MediaQuery greaterThan="medium">
            <ImageA
              fluid={"fluid" in imageA ? imageA.fluid : ""}
              alt={imageAlt}
            />
          </MediaQuery>
        </Column>

        <Column>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <DescriptionExtended>{description}</DescriptionExtended>

            {link && cta && cta.indexOf("Sign up") !== -1 && (
              <NavButtonLink href={link}>
                <ExtendedButton solid>{cta ? cta : "Read more"}</ExtendedButton>
              </NavButtonLink>
            )}

            {(link && cta === undefined) ||
              (cta && cta.indexOf("Sign up") === -1 && (
                <NavButtonLink href={link}>
                  <ExtendedButton variant="primarylight" solid>
                    {cta ? cta : "Read more"}
                  </ExtendedButton>
                </NavButtonLink>
              ))}
          </Text>
        </Column>
      </Columns>
    </ContentExtended>
  </Container>
);

export default HomeConversion;
